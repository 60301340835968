import '@babel/polyfill';
import Vue from 'vue';
import './styles/quasar.scss'
import 'quasar/dist/quasar.ie.polyfills'
import lang from 'quasar/lang/zh-hans.js'
import enlang from 'quasar/lang/en-us.js'
import Notify from "quasar/src/plugins/Notify";
import Loading from "quasar/src/plugins/Loading";
import Dialog from "quasar/src/plugins/Dialog";
import '@quasar/extras/material-icons/material-icons.css'
import Quasar from 'quasar'
import "./assets/css/reset.scss";
import "swiper/swiper-bundle.css";
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay,Navigation } from 'swiper/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
import * as GmapVue from 'gmap-vue'
import router from './router';
import api from '@/api/api';
import Cookies from "js-cookie";
import VueI18n from 'vue-i18n'
import messages from './i18n/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

let location = window.location;
let origin = location.origin; //'http://www.blueoceanboston.com'// location.origin;
let I18nLocale = "zh-CN";
let quasarLang = enlang;
if(origin.indexOf("bostonfang")>-1){
  I18nLocale = "zh-CN";
  quasarLang = lang;
}

if(origin.indexOf("blueoceanboston")>-1){
  I18nLocale = "en-US";
  quasarLang = enlang;
}

Vue.use(Quasar, {
    config: {
      notify:{
        position:'top'
      },
      loading:{}
    },
    plugins: {
      Notify,
      Loading,
      Dialog
    },
    lang: quasarLang
  }
)


Vue.use(VueI18n)

import App from './App.vue';

Vue.config.productionTip = false;

// Swiper modules
SwiperClass.use([Pagination, Mousewheel, Autoplay,Navigation]);
// Global use
Vue.use(getAwesomeSwiper(SwiperClass))
Vue.use(GmapVue,{
  load:{
    key:"AIzaSyA-3UJRvcyHKG-r8WhmBmHVuKf6BcbDkMk",
    libraries:"drawing"
  },
  installComponents:true
})

Vue.prototype.$api = api;

Vue.filter("price2thousandth",function(price){
  price = price + "";
  return price.replace(/\B(?=(\d{3})+\b)/g,',');
})



const i18n = new VueI18n({
  locale: I18nLocale,
  messages: messages,
  fallbackLocale: I18nLocale,
})
document.title = i18n.t("company");
// router.beforeEach((to,from,next)=>{
//   if (Object.keys(to.query).length) {
//     next();
//     return;
//   }
//   // 对URL路径参数进行处理
//   if (Object.keys(from.query).length) {
//     let toQuery = JSON.parse(JSON.stringify(from.query));
//     next({ path: to.path, query: toQuery });
//   } else {
//     next();
//   }
// })
let vm = new Vue({
  router,
  render: (h) => h(App),
  i18n,
}).$mount('#app');
api.initVue(vm);

;(async function(){
  let res = await api.requestGetJSESSIONID();
  if(res){
    let JSESSIONID = res.JSESSIONID;
    Cookies.set("JSESSIONID",JSESSIONID)
  }
})()
