<template>
  <div class="home__body">
    <div class="banner__box">
      <!--焦点图-->
      <swiper
        :options="swiperOptions"
      >
        <swiper-slide><img src="../assets/images/home/new01.jpg" alt=""></swiper-slide>
        <swiper-slide><img src="../assets/images/home/new02.jpg" alt=""></swiper-slide>
        <swiper-slide><img src="../assets/images/home/new03.jpg" alt=""></swiper-slide>
        <swiper-slide><img src="../assets/images/home/new04.jpg" alt=""></swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="search__box_new">
        <h2 class="slogan__title">{{$t('banner.slogan')}}</h2>
        <!-- <q-select
          filled
          v-model="search.value"
          use-input
          input-debounce="500"
          :placeholder="$t('banner.searchplace')"
          :options="search.list"
          dense
          @filter="searchArea"
          @input="searchInput"
          lazy-rules
          bg-color="grey-1"
          hide-dropdown-icon
          behavior="menu"
        >
          <template v-slot:append>
            <q-icon v-if="search.value !== ''" name="close" @click.stop="search.value = ''" class="cursor-pointer" />
            <q-icon name="search" @click.stop />
          </template>
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No results
              </q-item-section>
            </q-item>
          </template>
        </q-select> -->
        <div style="position: relative; width: 530px;">
          <el-select
            v-model="search.value"
            filterable
            remote
            reserve-keyword
            :placeholder="$t('banner.searchplace')"
            :remote-method="searchAreaNew"
            @change="searchInput"
            :loading="loading">
            <el-option
              v-for="item in search.list"
              :key="item.id"
              :label="item.label"
              :value="item">
            </el-option>
          </el-select>
          <i class="el-icon-search"></i>
        </div>
      </div>
    </div>
    <div class="container__box">
      <!--  买房  -->
      <h2 class="h2-title">{{$t('ourService')}}</h2>
      <div class="column__box mt50 mb50">
        <router-link class="column__box--info" to="/buy/list">
            <div class="one"></div>
            <h3 class="column__box--info--title">{{$t('homePage.buyahome.title')}}</h3>
            <p class="column__box--info--content">{{$t('homePage.buyahome.info')}}</p>
            <q-btn class="mt20" style="padding: 0 12px;" color="primary" :label="$t('homePage.buyahome.more')" />
        </router-link>
        <router-link class="column__box--info" to="/page/sell">
          <div class="two"></div>
            <h3 class="column__box--info--title">{{$t('homePage.sellahome.title')}}</h3>
            <p class="column__box--info--content">{{$t('homePage.sellahome.info')}}</p>
            <q-btn class="mt20" style="padding: 0 12px;" color="primary" :label="$t('homePage.sellahome.more')" />
        </router-link>
        <router-link class="column__box--info" to="/rent/list">
          <div class="three"></div>
            <h3 class="column__box--info--title">{{$t('homePage.rentahome.title')}}</h3>
            <p class="column__box--info--content">{{$t('homePage.rentahome.info')}}</p>
            <q-btn class="mt20" style="padding: 0 12px;" color="primary" :label="$t('homePage.rentahome.more')" />
        </router-link>
      </div>
    </div>
    <div class="body__column--box mt10" style="background: #f0f0f0;">
      <div class="container__box">
        <h2 class="h2-title">{{$t('aboutUs')}}</h2>
        <div class="page__content--box"style="background: #fff;">
          <div class="page__content_img"></div>
          <div class="page__content--section">
            <p> {{$t('homePage.copyright.content')}}</p>
            <p class="seeMore" @click="linkAbout">{{$t('seeMore')}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="searchBanner">
      <h3>What are you  waiting for?</h3>
      <h3>Start detecting today.</h3>
      <div class="search__box">
        <el-select
          v-model="search.value"
          filterable
          remote
          reserve-keyword
          :placeholder="$t('banner.searchplace')"
          :remote-method="searchAreaNew"
          @change="searchInput"
          :loading="loading">
          <el-option
            v-for="item in search.list"
            :key="item.id"
            :label="item.label"
            :value="item">
          </el-option>
        </el-select>
        <i class="el-icon-search"></i>
      </div>
    </div>
<!--    <div class="body__column&#45;&#45;box mt50">-->
<!--      <div class="container__box">-->
<!--        <div class="fromblog__box">-->
<!--          <h3 class="home__title">十几年的经验，为您的资产保驾护航！</h3>-->
<!--          <div class="fromblog__body">-->
<!--            <ul class="fromblog__list">-->
<!--              <li>-->
<!--                <h4 class="fromblog__item&#45;&#45;title">买房</h4>-->
<!--                <div class="fromblog__item&#45;&#45;content">-->
<!--                  <div class="fromblog__item&#45;&#45;header">Posted on January 28, 2017</div>-->
<!--                  <p class="fromblog__item&#45;&#45;text">Blue Ocean Realty（ 波士顿房产网）由曹悦吉(Rose Hall)女士于2011 年创办，自成立以来迅速成为麻州领先的地产经纪，投资和咨询公司，连年成交总额超过上亿美金，连续数年成为全麻州双语团队遥遥领先的第一名。</p>-->
<!--                </div>-->
<!--              </li>-->
<!--              <li>-->
<!--                <h4 class="fromblog__item&#45;&#45;title">卖房</h4>-->
<!--                <div class="fromblog__item&#45;&#45;content">-->
<!--                  <div class="fromblog__item&#45;&#45;header">Posted on January 28, 2017</div>-->
<!--                  <p class="fromblog__item&#45;&#45;text">波士顿房产网拥有分工明确的专家团队，负责专业摄影，房屋装扮，价格分析，宣传推广等环节，用十几年的售房经验和社会关系为您保驾护航。</p>-->
<!--                </div>-->
<!--              </li>-->
<!--              <li>-->
<!--                <h4 class="fromblog__item&#45;&#45;title">房产托管</h4>-->
<!--                <div class="fromblog__item&#45;&#45;content">-->
<!--                  <div class="fromblog__item&#45;&#45;header">Posted on January 28, 2017</div>-->
<!--                  <p class="fromblog__item&#45;&#45;text">波士顿房产网旗下管理公司已托管了超过500套房，管理房产遍布全麻州。拥有十多位全职房屋管理专业人员和十多位维修人员，24小时接听维修电话，周一到周五9:30AM-5:30 PM无间断实体办公，为客户提供双语服务。</p>-->
<!--                </div>-->
<!--              </li>-->
<!--              <li>-->
<!--                <h4 class="fromblog__item&#45;&#45;title">房屋租赁</h4>-->
<!--                <div class="fromblog__item&#45;&#45;content">-->
<!--                  <div class="fromblog__item&#45;&#45;header">Posted on January 28, 2017</div>-->
<!--                  <p class="fromblog__item&#45;&#45;text">除了提供专业的物业管理还提供管家式租赁管理服务，与百家租房平台合作宣传房源。后期拥有专业的审核以及文书团队确保房东利益，获得超出预期的投资回报！对于海外客户，可以做到足不出户，无忧托管。</p>-->
<!--                </div>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="announce__box">-->
<!--          <p>{{$t('homePage.copyright.content')}}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<style scoped lang="scss">
  .home__body /deep/ {
    background: #f0f0f0;
    .home__title{
      font-size: 40px;
      text-align: center;
      font-family: "Ivar Headline", "Ivar Headline Subset", "Times New Roman", serif;
      font-weight: bold;
    }
    /* .buy__box{
      padding-top: 50px;
      padding-bottom: 50px;
      background: url("../assets/images/Buy_a_home.png") bottom right no-repeat #fff;
      background-size: 40%;
      height: 480px;
      .buy__content{
        font-size: 20px;
        font-family: false;
        font-weight: 100;
        line-height: 34px;
        font-family: "Ivar Headline", "Ivar Headline Subset", "Times New Roman", serif;
        width: 950px;
        margin: 40px auto 0;
        p{
          margin-bottom: 15px;
        }
      }
    } */
    /*信息展示*/
    .column__box{display: flex;}
    .column__box--info{
      height: 410px;
      padding: 0 60px;
      box-sizing: border-box;
      background-color: #fff;
      padding-top: 62px;
      text-align: center;
      width: 380px;
      .column__box--info--title{
        color: #443227;
        font-size:36px;
      }
      &:hover{
        box-shadow: 0 0 10px #ccc;
      }
    }
    .column__box--info .one{
      background: url("../assets/images/home/maifang.png") center no-repeat #fff;
      width: 160px;
      height: 130px;
      margin: 0 auto;
    }
    .column__box--info .two{
      background: url("../assets/images/home/maifang2.png") center no-repeat #fff;
      width: 160px;
      height: 130px;
      margin: 0 auto;
    }
    .column__box--info .three{
      background: url("../assets/images/home/zf.png") center no-repeat #fff;
      width: 160px;
      height: 130px;
      margin: 0 auto;
    }
    .column__box--info:nth-of-type(2){
      margin: 0 80px;
    }
    .column__box--info--title{
      margin-bottom: 10px;
      font-weight: 700;
      font-family: "Ivar Headline", "Ivar Headline Subset", "Times New Roman", serif;
    }
    .column__box--info--content{
      line-height: 26px;
      font-size: 16px;
      color: #443227;
      font-weight: 400;
      font-family: "Open Sans", Tahoma, Geneva, sans-serif;
    }

    /*房屋信息*/
    .house__list{display: flex;margin-left: 20px;}
    .house__item{
      width: 280px;
      height: 370px;
      border: 1px solid #ccc;
      margin-right: 27px;
      background: #fff;
      box-shadow: 0 0 10px #ccc;
      &:last-of-type{
        margin-right: 0;
      }
      a{
        display: block;
      }
    }

    .house__banner{height: 175px;}
    .house__banner img{width: 100%;height: 175px;}
    .house__info{padding: 10px;}
    .house__name{margin-top: 10px;color: #007789;font-size: 20px;line-height: 27px;font-family: false;font-weight: 200;}
    .house__info--text{margin-top: 10px;color: #666;font-size: 13px;line-height: 20px;font-family: false;font-weight: 200;}
    .fromblog__box{
      padding: 50px 0;
      .fromblog__body{
        margin-top: 30px;
        .fromblog__list{
          display: flex;
          flex-wrap:wrap;
          justify-content:space-around;
          li{
            flex: 1;
            margin-right: 15px;
            &:nth-of-type(4){
              margin-right: 0;
            }
          }
          .fromblog__item--title{
            font-size: 21px;
            background: #4599d1;
            color: #fff;
            padding: 15px 25px;
          }
          .fromblog__item--content{
            background: #f2f2f2;
            padding: 20px;
            height: 200px;
            box-sizing: content-box;
            .fromblog__item--header{
              color: #373737;
              margin-bottom: 15px;
              font-size: 14px;
            }
            .fromblog__item--text{
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }

    }

    /*声明*/
    .announce__box{
      font-size: 18px;
      line-height: 28px;
      font-family: "Ivar Headline", "Ivar Headline Subset", "Times New Roman", serif;
      padding-bottom: 50px;
    }
    
    .searchBanner{
      padding-top: 50px;
      height: 319px;
      width: 100%;
      background: url('../assets/images/home/searchBanner.jpg') no-repeat top center;
      overflow: hidden;
      color: #F5F6F7;
      box-sizing: content-box;
      position: relative;
      h3{
        font-size: 40px;
        font-weight:400;
        text-align:center;
        line-height:58px;
      }
    }

    .h2-title{
      color: #443227;
      font-size: 60px;
      text-align: center;
      margin: 100px 0;
    }
  }

</style>

<script>
export default {
  name: 'Home',
  data(){
    return {
      loading:false,
      search:{
        value:"",
        list:[]
      },
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable:true
        },
        autoplay:{
          delay:3000,
          disableOnInteraction:false
        }
      },
      searchFocus:false
    }
  },
  mounted(){
    this.$nextTick(() =>{
      window.addEventListener("scroll",this.handleScroll)
    })
  },
  methods:{
    handleScroll(){
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 80) {
          //滚动大于80的时候要做的操作
          document.getElementById('headNav').classList.add('scrollPage')
      }else{
        document.getElementById('headNav').classList.remove('scrollPage')
      }
    },
    async searchAreaNew(name){
      if (name !== '') {
        this.loading = true;
        let form = new FormData();
        form.append("polygon","@mlspin")
        let res = await this.$api.requestAreasSearch(form,{
          headers: {
            "Content-Type":"application/x-www-form-urlencoded"
          },
          params: {
            geoType: ["area/postal-city","area/census-place","area/township","area/county","area/neighborhood","area/zipcode"],
            keyword: name,
            sortField: "population",
            sortOrder: "desc",
            bias: "postal-city",
            clipping: "intersection"
          }
        });
        this.loading = false;
        let result = res.result;
        let schools = result.areas;
        this.search.list = schools;
      } else {
        this.search.list = [];
      }
    },
    async searchArea(name, update, abort){
      this.loading = true;
      if(!name){
        update();
        return false;
      }
      let form = new FormData();
      form.append("polygon","@mlspin")
      let res = await this.$api.requestAreasSearch(form,{
        headers: {
          "Content-Type":"application/x-www-form-urlencoded"
        },
        params: {
          geoType: ["area/postal-city","area/census-place","area/township","area/county","area/neighborhood","area/zipcode"],
          keyword: name,
          sortField: "population",
          sortOrder: "desc",
          bias: "postal-city",
          clipping: "intersection"
        }
      });
      this.loading = false;
      let result = res.result;
      let schools = result.areas;
      let _this = this;
      update(()=>{
        _this.search.list = schools;
      })
    },
    searchInput(value){
      this.$router.push({
          name:"buy",
          query:{
            name:value.label,
            id:value.id
          }
        }
      )
    },
    linkAbout(){
      this.$router.push(
        {
          path:'page/about'
        }
      )
    }
  },
  destroyed(){
    window.removeEventListener("scroll",this.handleScroll)
  },
  watch:{
    "$root.$i18n.locale"(n,o){
      this.$forceUpdate();
    }
  }
};
</script>
