import axios from 'axios';

axios.defaults.baseURL ='/blueocean';
axios.defaults.timeout = 3 * 60 * 1000;
axios.defaults.withCredentials = true;
let notify = false;
//http request 拦截器
axios.interceptors.request.use(
  config => {
    let vm = axios.prototype.vm
    let loading = config.loading;
    notify = config.notify;
    if(loading){
      vm.$q.loading.show()
    }
    return config;
  },
  error => {
    return Promise.reject(err);
  }
);

//http response 拦截器
axios.interceptors.response.use(
  response => {
    let vm = axios.prototype.vm
    let res = response.data;
    let code = parseInt(res.code);
    let msg = res.message;
    vm.$q.loading.hide()
    if(code === 1250){
      vm.$children[0].login.status = true;
    }
    if(code === 1000 && notify){
      vm.$q.notify({
        message:msg,
        position:"top",
        type: 'positive'
      });
    }
    if(code>1000){
      vm.$q.notify({
        message:msg,
        position:"top",
        type: 'negative'
      });
      return Promise.reject(res)
    }
    return response;
  },
  (error) => {
    let vm = axios.prototype.vm
    vm.$q.loading.hide()
    return Promise.reject(error)
  }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

function get(url,params={},config){
  let c = Object.assign({},{params},config)
  return new Promise((resolve,reject) => {
    axios.get(url,c)
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err)
      })
  })
}
/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function post(url,data = {},config){
  return new Promise((resolve,reject) => {
    axios.post(url,data,config)
      .then(response => {
        resolve(response.data);
      },err => {
        reject(err)
      })
  })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function patch(url,data = {}){
  return new Promise((resolve,reject) => {
    axios.patch(url,data)
      .then(response => {
        resolve(response.data);
      },err => {
        reject(err)
      })
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function put(url,data = {}){
  return new Promise((resolve,reject) => {
    axios.put(url,data)
      .then(response => {
        resolve(response.data);
      },err => {
        reject(err)
      })
  })
}
/**
 * 封装delete请求
 * @param  url
 * @param  params
 * @returns {Promise}
 */
function Delete(url,params={}){
  return new Promise((resolve,reject) => {
    axios.delete(url,{
      params:params
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err)
      })
  })
}

export default {
  axios,
  get,
  post,
  put,
  Delete,
  initVue(vm) {
    axios.prototype.vm = vm;
  }
}
