export default {
  closeBtn:'关闭',
  chinaName:'中文',
  backTop:'回到顶部',
  gzxcx:'关注小程序',
  gzgzh:'关注公众号',
  wxzy:'微信咨询',
  emailNum:'邮箱',
  telNum:'电话',
  wconsolt:'欢迎咨询',
  fangchanweb:'波士顿房产网',
  copyrightaddress:'沪ICP备20004772号-2',
  tigongfangyuan:'为您提供以下房源',
  placeholderSelect:'请选择',
  areaCovered:'占地面积',
  guanjianci:'关键词',
  placeholderAll:'全部',
  zhiM:'至',
  seeMore:'查看更多',
  aboutUs:'关于我们',
  ourService:'我们的服务',
  cankaoyuegong:'参考月供(元)',
  stowcondition:'收起条件',
  otherLabel:'其他',
  cityStreet:'城市/街区',
  priceLabel:'价格',
  houseYear:'房龄',
  login:{
    label:"登录/注册",
    mobile:"您的手机号码 *",
    captcha:"验证码",
    typeMobileTip:"请输入您的手机号码",
    typeCodeTip:"请输入验证码",
    send:"发送",
    loginOut:"退出"
  },
  company:"波士顿房产网",
  nav:{
    HOME:"主页",
    BUY:"买房",
    RENT:"租房",
    SELL:"卖房",
    ABOUT:"关于我们",
    CALCULATOR:"房贷计算器",
    TRUSTEESHIP:"房屋托管",
  },
  banner:{
    slogan:"开始寻找属于你的新家",
    searchplace:"请输入地址，区域，城市或邮编"
  },
  homePage:{
    buyahome:{
      title:"买房",
      info:"最丰富的房源信息和最专业的购房服务，给您最安心的体验",
      more:"查看更多"
    },
    sellahome:{
      title:"卖房",
      info:"最充分的房源展示和最专业的销售服务，帮您实现最佳的收益",
      more:"查看更多"
    },
    rentahome:{
      title:"租房",
      info:"最强大的房源网络和最贴心的找房服务，给您找到最合适的家",
      more:"查看更多"
    },
    blog:{
      title:"我们的博客"
    },
    copyright:{
      content:"提供给MLS Property Information Network, INC的房源数据和信息或图片均来自第三方，包括卖家，承租方，房东和公共记录，经由MLS Property Information Network, INC统合。房源数据和信息以及图片是给非商业的且有真实的购买或租赁展示给他们的相关类型挂牌物产的个人，并且不能用于除确认潜在的有真实意愿去购买或租赁的物产以外的任何目的。MLS Property Information Network, Inc和它的订阅者声明关于所有房源数据和信息以及图片的陈述和保证是准确的。隐私政策通知：所有您自愿提供的信息将被用于个性化您的体验，改善我们的网站和改善我们的服务。这些信息不会被分享给任何第三方个人或实体。"
    }
  },
  footer:{
    rule:"只有是NATIONAL ASSOCIATION OF REALTORS®成员的房地产专业人员才可以自称REALTORS®。所有的REALTORS®必须持续遵守NAR严格的基于诚信和专业精神并出于维护公道目的的道德标准。",
    address:"15 North Beacon Street, Suite NR1A, Boston, MA 02134",
    email:"给我们发邮件"
  },
  filter:{
    type:{
      Residential:"出售",
      Multifamily:"多家庭",
      Land:"土地",
      Commercial:"商业",
    },
    price:{
      label:"价格"
    },
    propertyType:{
      label: "物产类型",
      Residential:{
        Condominium:"公寓",
        ForestServiceCabin:"守林木屋",
        MobileHome:"移动房产",
        PartOwnership:"部分所有权 C/T",
        SingleFamilyResidence:"独栋别墅",
        Townhouse:"联排别墅"
      },
      Multifamily:{
        Build:"5-12单元",
        Duplex:"2单元",
        Quadruplex:"4单元",
        Triplex:"3单元"
      },
      Land:{
        Land:"空地",
        Allocation:"分摊空地"
      },
      Commercial:{
        Industrial:"工业",
        Motel:"酒店",
        Office:"办公",
        Professional:"办公,专业服务",
        RetailSpace:"办公,零售,其它/见备注",
        OtherSee:"其它/见备注",
        ProfessionalService:"专业服务",
        ServiceRemarks:"专业服务/见备注",
        GroceryStore:"零售,商场,其他/见备注",
      }
    },
    bedrooms:{
      label:"卧室"
    },
    bathrooms:{
      label:"卫生间"
    },
    square:{
      label:"平方英尺"
    },
    keyword:{
      label:"关键词"
    },
    year:{
      label:"年份"
    },
    lotSize:{
      label:"占地面积"
    },
    status:{
      label:"状态",
      active:"在出售",
      activepending:"暂被预订"
    }
  },
  PropertyFactsFeatures:"事实与特点",
  SchoolsNearby:"附近的学校",
  school:{
    tabs:{
      Assigned:"指定学校",
      Elementary:"小学",
      Middle:"初中",
      High:"高中"
    }
  },
  listSort:{
    Newest:"最新",
    Cheapest:"最便宜",
    OpenHouse:"开放展示",
    Price:"价格 (高 - 低)",
    Largest:"最大面积",
    YearBuilt:"建造年份",
  },
  favourite:{
    toBuyFavourite:"买房最爱",
    toRentFavourite:"租房最爱",
  },
  detail:{
    beds:"卧室",
    baths:"卫生间",
    sqft:"平方英尺",
    HomeDetails:"房屋细节",
    RATING:"评价",
    SCHOOLNAME:"学校名称",
    ASSIGNEDGRADES:"指定年级",
    DISTANCE:"距离",
    STUDENTS:"学生数量",
    STUDENTTEACH:"学生/教师比例",
    CHARTER:"教会学校",
    TITLE1:"1类学校",
    EXPENDITURE:"费用",
    Parking:"停车",
    NODATA:"您没有挂牌信息"
  },
  sell:{
    when:{
      title:"如何选择最佳卖房时机？",
      content:`
        <p>Times are changing and you’re thinking about moving the family into a new home. But before you start searching for your next dream home, you’ll need to figure out when you’re going to sell your existing home. The decision is a complex one, especially considering the unpredictable economic environment you’re living in. And while there’s no magic formula for determining the best time to sell a home, there are several factors you should consider.</p>
            <h5>The wrong time to sell</h5>
            <p>This likely won’t come as a big surprise: you should avoid selling your home during the winter holiday season. Tensions run high during the holidays and budgets run low. The result? No one’s really itching to buy, and offers that do come in are often low. So as a general rule of thumb, avoid the months of December and January when selling a home.</p>
            <h5>The best season for selling</h5>
            <p>Conventional wisdom dictates that spring is the best time to sell a home. In spring, the holidays have past, the new school year is still a while away, and gardens and other outdoor spaces tend to look their best. And while spring is the season that sees the largest amount of buyers, it’s also the season when inventories are highest. This means lots of competition, so if you’re going to sell in spring, you really need to get your property to stand out with effective marketing and staging.
            </p>
            <h5>The best day for selling</h5>
            <p>According to research, Thursday is the best day of the week to list your home for sale. By listing your home for sale on a Thursday, you can make it available immediately for weekend showings. Come Saturday – the busiest day for real estate – your home will have only been on sale for two days, which is great for attracting full-price offers. In general, the shorter amount of time your home remains on the market, the higher the offer you’ll receive.</p>
      `
    },
    questions:{
      title:"卖房前需要问自己的4个问题",
      content:`
      <p>Selling your home is a big financial and emotional task, which requires lots of planning. So before you decide to put your home on the market, here are four questions to consider.</p>
            <h5>What is my home worth?</h5>
            <p>Several factors go into determining your home’s market value. These include the condition of the property, square footage, location, amenities, as well as recent comparable property sales. For a snapshot of your home’s current value, you can enter your address into a free online value estimator tool. Alternatively, you can look at a recent property tax bill to get your home’s tax assessed value, which is a percentage of your home’s fair market value. If you know your state’s assessment rate (which is usually between 80 and 90 percent), you can estimate fair market value by dividing your tax assessed value by that assessment rate.
            </p>
            <h5>How can I sell at the highest price possible?</h5>
            <p>There are several simple, low-cost things you can do to boost the value of your home. These include eliminating clutter, washing windows and walls, and installing higher wattage bulbs to create a brighter environment. Larger projects, like adding bedrooms and renovating kitchens and bathrooms, typically yield more substantial increases in sales price. Staging your home, which helps paint a picture for how buyers will use their new space, can also add value.
            </p>
            <h5>How long will my home be on the market?</h5>
            <p>The time your home spends on the market depends on several factors, including location, how competitively your home is priced, and how aggressively your home is marketed. Statistics show that if a home is properly priced, an offer should come in within the first 12 showings. If you want to speed up the sales process, don’t underestimate the value of staging. Research shows that well-staged homes can sell up to 32 percent faster than homes that aren’t staged.</p>
            <h5>How can an agent help?</h5>
            <p>A real estate agent can help with all aspects of the selling process, from pricing to marketing to handling the intricacies of closing. An agent will negotiate on your behalf to secure the best price – and quickest sale – possible. Looking for an agent who can help guide you through the selling process? Feel free to contact us!</p>`
    }
  },
  about:{
    about:{
      title:"关于我们",
      content:`
        <p>We understand that buying or selling a home is more than just a transaction: it’s a life-changing experience. That’s why our team of highly-seasoned real estate professionals is dedicated to providing exceptional, personalized service for all of our clients. We take great pride in the relationships we build and always work relentlessly on the client’s behalf to help them achieve their real estate goals.</p>
            <p>Our team of experts represents the best and brightest in the industry, and we’re always striving to lead the field in research, innovation, and consumer education. Today’s buyers and sellers need a trusted resource that can guide them through the complex world of real estate. With our extensive knowledge and commitment to providing only the best and most timely information to our clients, we are your go-to source for real estate industry insight and advice.</p>
            <h5>Our Mission Statement:</h5>
            <p>Our philosophy is simple: clients come first. We pledge to be in constant communication with our clients, keeping them fully informed throughout the entire buying or selling process. We believe that if you’re not left with an amazing experience, we haven’t done our job. We don’t measure success through achievements or awards, but through the satisfaction of our clients.</p>
            <h5>Choosing a home</h5>
            <p>For most buyers, choosing a home is an emotional process. An agent can assist you in this process by offering objective information about each property you look at. From local community information like schools and zoning to home-specific details like condition and amenities, an agent can help you find exactly what you’re looking for.</p>
            <h5>Making an offer</h5>
            <p>Once you’ve found the home of your dreams, an agent will research recent comparable sales of similar homes in the area to help determine a fair selling price. Based on those comparable sales, as well as other factors like inspections and repairs, an agent will then help you structure an offer and negotiate to get the very best deal possible.</p>
            <h5>Closing</h5>
            <p>Closing, or settlement, can be a complicated process. In some areas, the escrow or title company handles the closing process, while in other areas an attorney handles it. Regardless of where you’re buying, a real estate agent can help ensure that everything goes smoothly.</p>
      `
    },
    analyst:{
      title:"分析师",
      content:`
            <h5>Blue Ocean Realty LLC seeks Analyst.</h5>
            <h5>Master’s degree in Business Administration required. </h5>
            <br><br>
            <p>Job duties:</p>
            <p>1. Analyze and evaluate current real estate investments: including examining financial statements using technical methodologies such as liquidity analysis, etc.</p>
            <p>2. Develop forecasting models for the company to utilize including financial modeling of property rent rolls</p>
            <p>3. Perform financial analysis on the company’s prospective investments including the use of real estate finance methodologies such as debt service coverage ratio, loan to value ratio, and debt yield</p>
            <p>4. Prepare reports for presenting the results of financial analysis to company management</p>
            <p>5. Other related tasks as assigned</p>
            <p>Work Site: Allston (Boston), MA</p>
            <br>
            <br>
            <p>Mail resume to:</p>
            <p>Blue Ocean Realty LLC</p>
            <p>Attn: Rose Hall</p>
            <p>15 N. Beacon Street, Suite NR1A</p>
            <p>Allston (Boston), MA 02134</p>
      `
    },
    contact:{
      title:"请输入以下信息我们的专家团队会立刻联系你评估",
      title2:"联系我们",
      Name:"您的姓名（必填）",
      Email:"您的邮箱地址（必填）",
      Phone:"您的电话号码（必填）",
      Wechat:"您的微信号",
      questions:"请填写您需要备注的额外信息",
      Send:"提交",
      SendSuccess:"发送成功",
      address:"您的住房地址（必填，地址尽量详细一些）",
      formTitle:'房屋托管',
      sellTitle:'卖房'
    }
  },
  min:"最小",
  max:"最大",
  any:"全部",
  keword:"街道名, MLS代号 或 关键字",
  Acre:"英亩",
  Status:"状态",
  More:"更多",
  calculator:{
    debx:"等额本息",
    debj:"等额本金",
    ckyg:"参考月供",
    yuan:"元",
    mydj:"每月递减",
    hkze:"还款总额",
    zflx:"支付利息",
    dklx:"贷款类型",
    sydk:"商业贷款",
    dkje:"贷款金额",
    inputmoney:"请输入金额",
    wan:"万",
    dknx:"贷款年限",
    sdlv:"商贷利率",
    qsrsdlv:"请输入商贷利率",
  },
  rent:{
    Enterastreename:"请输入街道名",
    CityHeighborhood:"城市/街区",
    Any: "全部",
    Acton: "Acton",
    Andover: "Andover",
    Ballardvale: "- Ballardvale",
    InTown: "- In Town",
    Shawsheen: "- Shawsheen",
    ShawsheenHeights: "- Shawsheen Heights",
    SouthAndover: "- South Andover",
    WestAndover: "- West Andover",
    Arlington: "Arlington",
    ArlingtonCenter: "- Arlington Center",
    ArlingtonHeights: "- Arlington Heights",
    BrattleStation: "- Brattle Station",
    EastArlington: "- East Arlington",
    Morningside: "- Morningside",
    Ashland: "Ashland",
    Attleboro: "Attleboro",
    SouthAttleboro: "- South Attleboro",
    Auburn: "Auburn",
    Auburndale: "- Auburndale",
    Bedford: "Bedford",
    Belmont: "Belmont",
    PaysonPark: "- Payson Park",
    Waverley: "- Waverley",
    Berkley: "Berkley",
    Berkshire: "Berkshire",
    Berlin: "Berlin",
    Beverly: "Beverly",
    BeverlyCove: "- Beverly Cove",
    BeverlyFarms: "- Beverly Farms",
    Briscoe: "- Briscoe",
    "Centerville(Bvly)": "- Centerville (Bvly)",
    Montserrat: "- Montserrat",
    NorthBeverly: "- North Beverly",
    PridesCrossing: "- Prides Crossing",
    RyalSide: "- Ryal Side",
    Billerica: "Billerica",
    EastBillerica: "- East Billerica",
    NorthBillerica: "- North Billerica",
    NuttingLake: "- Nutting Lake",
    Pattenville: "- Pattenville",
    Pinehurst: "- Pinehurst",
    SouthBillerica: "- South Billerica",
    Boston: "Boston",
    Allston: "- Allston",
    BackBay: "- Back Bay",
    BayVillage: "- Bay Village",
    BeaconHill: "- Beacon Hill",
    Brighton: "- Brighton",
    Charlestown: "- Charlestown",
    Chinatown: "- Chinatown",
    Dorchester: "- Dorchester",
    EastBoston: "- East Boston",
    Fenway: "- Fenway",
    FinancialDistrict: "- Financial District",
    FortHill: "- Fort Hill",
    HydePark: "- Hyde Park",
    JamaicaPlain: "- Jamaica Plain",
    Kenmore: "- Kenmore",
    LeatherDistrict: "- Leather District",
    Mattapan: "- Mattapan",
    Midtown: "- Midtown",
    MissionHill: "- Mission Hill",
    NorthEnd: "- North End",
    Roslindale: "- Roslindale",
    Roxbury: "- Roxbury",
    SeaportDistrict: "- Seaport District",
    SouthBoston: "- South Boston",
    SouthEnd: "- South End",
    TheatreDistrict: "- Theatre District",
    Waterfront: "- Waterfront",
    WestEnd: "- West End",
    WestRoxbury: "- West Roxbury",
    Boxborough: "Boxborough",
    Boylston: "Boylston",
    Braintree: "Braintree",
    BraintreeHighlands: "- Braintree Highlands",
    EastBraintree: "- East Braintree",
    FiveCorners: "- Five Corners",
    SouthBraintree: "- South Braintree",
    Brookline: "Brookline",
    Beaconsfield: "- Beaconsfield",
    BrooklineHills: "- Brookline Hills",
    BrooklineVillage: "- Brookline Village",
    ChestnutHill: "- Chestnut Hill",
    CoolidgeCorner: "- Coolidge Corner",
    Longwood: "- Longwood",
    Reservoir: "- Reservoir",
    WashingtonSquare: "- Washington Square",
    Burlington: "Burlington",
    Cambridge: "Cambridge",
    Agassiz: "- Agassiz",
    CambridgeHighlands: "- Cambridge Highlands",
    Cambridgeport: "- Cambridgeport",
    CentralSquare: "- Central Square",
    EastCambridge: "- East Cambridge",
    HarvardSquare: "- Harvard Square",
    HuronVillage: "- Huron Village",
    InmanSquare: "- Inman Square",
    KendallSquare: "- Kendall Square",
    MidCambridge: "- Mid Cambridge",
    NeighborhoodNine: "- Neighborhood Nine",
    NorthCambridge: "- North Cambridge",
    PorterSquare: "- Porter Square",
    Riverside: "- Riverside",
    WellingtonHarrington: "- Wellington-Harrington",
    WestCambridge: "- West Cambridge",
    Canton: "Canton",
    CantonJunction: "- Canton Junction",
    Ponkapoag: "- Ponkapoag",
    Springdale: "- Springdale",
    Chelsea: "Chelsea",
    Concord: "Concord",
    NineAcreCorner: "- Nine Acre Corner",
    WestConcord: "- West Concord",
    Danvers: "Danvers",
    BurleysCorner: "- Burleys Corner",
    DanversCenter: "- Danvers Center",
    Danversport: "- Danversport",
    EastDanvers: "- East Danvers",
    Hathorne: "- Hathorne",
    Putnamville: "- Putnamville",
    Tapleyville: "- Tapleyville",
    Dartmouth: "Dartmouth",
    NorthDartmouth: "North Dartmouth",
    SouthDartmouth: "South Dartmouth",
    Dedham: "Dedham",
    Ashcroft: "- Ashcroft",
    EastDedham: "- East Dedham",
    Endicott: "- Endicott",
    FairbanksPark: "- Fairbanks Park",
    Greenlodge: "- Greenlodge",
    Oakdale: "- Oakdale",
    ReadvilleManor: "- Readville Manor",
    Riverdale: "- Riverdale",
    TheManor: "- The Manor",
    Dover: "Dover",
    EastBridgewater: "East Bridgewater",
    Easton: "Easton",
    Everett: "Everett",
    Glendale: "- Glendale",
    WashingtonPark: "- Washington Park",
    WestEverett: "- West Everett",
    Fitchburg: "- Fitchburg",
    Foxboro: "Foxboro",
    Framingham: "Framingham",
    Coburnville: "- Coburnville",
    FraminghamCenter: "- Framingham Center",
    Lokerville: "- Lokerville",
    Nobscot: "- Nobscot",
    Saxonville: "- Saxonville",
    Franklin: "Franklin",
    Unionville: "- Unionville",
    Wadsworth: "- Wadsworth",
    Gardner: "Gardner",
    Gloucester: "Gloucester",
    Annisquam: "- Annisquam",
    BayView: "- Bay View",
    BrierNeck: "- Brier Neck",
    EastGloucester: "- East Gloucester",
    EasternPoint: "- Eastern Point",
    Lanesville: "- Lanesville",
    Magnolia: "- Magnolia",
    WestGloucester: "- West Gloucester",
    Hamilton: "Hamilton",
    SouthHamilton: "- South Hamilton",
    Harvard: "- Harvard",
    Huntington: "Huntington",
    Jefferson: "Jefferson",
    Kingston: "Kingston",
    IndianPond: "- Indian Pond",
    LakePleasant: "Lake Pleasant",
    Lancaster: "- Lancaster",
    Lawrence: "Lawrence",
    NorthLawrence: "- North Lawrence",
    SouthLawrence: "- South Lawrence",
    Lexington: "Lexington",
    NorthLexington: "- North Lexington",
    PiercesBridge: "- Pierces Bridge",
    Lowell: "Lowell",
    Belvidere: "- Belvidere",
    Centralville: "- Centralville",
    Downtown: "- Downtown",
    Highlands: "- Highlands",
    Pawtucketville: "- Pawtucketville",
    SouthLowell: "- South Lowell",
    Lynn: "Lynn",
    DiamondDistrict: "- Diamond District",
    EastLynn: "- East Lynn",
    LynnCommon: "- Lynn Common",
    LynnWoods: "- Lynn Woods",
    Lynnhurst: "- Lynnhurst",
    WestLynn: "- West Lynn",
    Wyoma: "- Wyoma",
    Lynnfield: "Lynnfield",
    SouthLynnfield: "- South Lynnfield",
    Malden: "Malden",
    BellRock: "- Bell Rock",
    BelmontHill: "- Belmont Hill",
    Edgeworth: "- Edgeworth",
    Faulkner: "- Faulkner",
    Fells: "- Fells",
    Forestdale: "- Forestdale",
    Linden: "- Linden",
    Maplewood: "- Maplewood",
    MiddlesexFells: "- Middlesex Fells",
    OakGrove: "- Oak Grove",
    Mansfield: "Mansfield",
    EastMansfield: "- East Mansfield",
    Robinsonville: "- Robinsonville",
    WestMansfield: "- West Mansfield",
    Whiteville: "- Whiteville",
    Marlborough: "Marlborough",
    EastMarlborough: "- East Marlborough",
    Medford: "Medford",
    CollegeHill: "- College Hill",
    MedfordHillside: "- Medford Hillside",
    NorthMedford: "- North Medford",
    TuftsUniversity: "- Tufts University",
    Wellington: "- Wellington",
    WestMedford: "West Medford",
    Melrose: "Melrose",
    MelroseHighlands: "- Melrose Highlands",
    MountHood: "- Mount Hood",
    Wyoming: "- Wyoming",
    Milford: "Milford",
    Milton: "Milton",
    MiltonVillage: "Milton Village",
    Natick: "Natick",
    EastNatick: "- East Natick",
    Felchville: "- Felchville",
    Morseville: "- Morseville",
    NorthNatick: "- North Natick",
    SouthNatick: "- South Natick",
    WestNatick: "- West Natick",
    Needham: "Needham",
    BirdsHill: "- Birds Hill",
    CharlesRiverVillage: "- Charles River Village",
    NeedhamHeights: "Needham Heights",
    NeedhamJunction: "- Needham Junction",
    NewBedford: "New Bedford",
    Center: "- Center",
    FarNorth: "- Far North",
    North: "- North",
    Northwest: "- Northwest",
    South: "- South",
    West: "- West",
    NewBraintree: "New Braintree",
    NewSalem: "New Salem",
    Newbury: "Newbury",
    Byfield: "- Byfield",
    PlumIsland: "- Plum Island",
    Newton: "Newton",
    Eliot: "- Eliot",
    NewtonCenter: "- Newton Center",
    NewtonCorner: "- Newton Corner",
    NewtonHighlands: "- Newton Highlands",
    NewtonLowerFalls: "- Newton Lower Falls",
    NewtonUpperFalls: "- Newton Upper Falls",
    Newtonville: "- Newtonville",
    Nonantum: "- Nonantum",
    Thompsonville: "- Thompsonville",
    Waban: "Waban",
    WestNewton: "- West Newton",
    WoodlandStation: "- Woodland Station",
    NorthAttleboro: "North Attleboro",
    NorthReading: "North Reading",
    MartinsPond: "- Martins Pond",
    WestVillage: "- West Village",
    Norton: "Norton",
    Norwood: "Norwood",
    "Orleans(village)": "- Orleans (village)",
    Peabody: "Peabody",
    NeedhamCorner: "- Needham Corner",
    SouthPeabody: "- South Peabody",
    WestPeabody: "- West Peabody",
    Plymouth: "Plymouth",
    CedarBushes: "- Cedar Bushes",
    Cedarville: "- Cedarville",
    Chiltonville: "- Chiltonville",
    Cordage: "- Cordage",
    Ellisville: "- Ellisville",
    LongPond: "- Long Pond",
    Manomet: "- Manomet",
    NamelocHeights: "- Nameloc Heights",
    NorthPlymouth: "- North Plymouth",
    Pinehills: "- Pinehills",
    Pondville: "- Pondville",
    Saquish: "- Saquish",
    SouthPlymouth: "- South Plymouth",
    SouthPond: "- South Pond",
    Vallersville: "- Vallersville",
    WestPlymouth: "- West Plymouth",
    Quincy: "Quincy",
    AdamsShore: "- Adams Shore",
    Atlantic: "- Atlantic",
    Germantown: "- Germantown",
    HoughsNeck: "- Houghs Neck",
    MarinaBay: "- Marina Bay",
    Merrymount: "- Merrymount",
    Montclair: "- Montclair",
    NorfolkDowns: "- Norfolk Downs",
    NorthQuincy: "- North Quincy",
    QuincyCenter: "- Quincy Center",
    QuincyNeck: "- Quincy Neck",
    QuincyPoint: "- Quincy Point",
    RockIsland: "- Rock Island",
    SouthQuincy: "- South Quincy",
    Squantum: "- Squantum",
    WestQuincy: "- West Quincy",
    Wollaston: "- Wollaston",
    WollastonHeights: "- Wollaston Heights",
    Reading: "Reading",
    Revere: "Revere",
    Beachmont: "- Beachmont",
    CrescentBeach: "- Crescent Beach",
    FranklinPark: "- Franklin Park",
    OakIsland: "- Oak Island",
    PointofPines: "- Point of Pines",
    RevereBeach: "- Revere Beach",
    Rochester: "Rochester",
    Salem: "Salem",
    BakersIsland: "- Bakers Island",
    ForestRiver: "- Forest River",
    GallowsHill: "- Gallows Hill",
    SalemNeck: "- Salem Neck",
    SalemWillows: "- Salem Willows",
    SouthSalem: "- South Salem",
    WinterIsland: "- Winter Island",
    Saugus: "Saugus",
    Sharon: "Sharon",
    Sherborn: "Sherborn",
    SouthSherborn: "- South Sherborn",
    Whitneys: "- Whitneys",
    Somerville: "Somerville",
    BallSquare: "- Ball Square",
    DavisSquare: "- Davis Square",
    EastSomerville: "- East Somerville",
    PowderhouseSquare: "- Powderhouse Square",
    ProspectHill: "- Prospect Hill",
    SpringHill: "- Spring Hill",
    TeeleSquare: "- Teele Square",
    UnionSquare: "- Union Square",
    WestSomerville: "- West Somerville",
    WinterHill: "- Winter Hill",
    Springfield: "Springfield",
    EastForestPark: "- East Forest Park",
    EastSpringfield: "- East Springfield",
    ForestPark: "- Forest Park",
    HillMcKnight: "- Hill McKnight",
    IndianOrchard: "- Indian Orchard",
    LibertyHeights: "- Liberty Heights",
    NEndBrightwood: "- N End Brightwood",
    PinePtBstnRd: "- Pine Pt Bstn Rd",
    SixteenAcres: "- Sixteen Acres",
    SoEndCentral: "- So End Central",
    Stoneham: "Stoneham",
    Stoughton: "Stoughton",
    Sudbury: "Sudbury",
    NorthSudbury: "- North Sudbury",
    SouthSudbury: "- South Sudbury",
    Swampscott: "Swampscott",
    Wakefield: "Wakefield",
    Walpole: "Walpole",
    Waltham: "Waltham",
    Watertown: "Watertown",
    Bemis: "- Bemis",
    EastWatertown: "- East Watertown",
    MountAuburn: "- Mount Auburn",
    Wellesley: "Wellesley",
    BabsonPark: "- Babson Park",
    Overbrook: "- Overbrook",
    WellesleyFarms: "- Wellesley Farms",
    WellesleyFells: "- Wellesley Fells",
    WellesleyHills: "Wellesley Hills",
    Wenham: "Wenham",
    WestBridgewater: "West Bridgewater",
    WestNewbury: "West Newbury",
    WestSpringfield: "West Springfield",
    Weston: "Weston",
    Hastings: "- Hastings",
    SilverHill: "- Silver Hill",
    Westwood: "Westwood",
    Wilmington: "Wilmington",
    Winchester: "Winchester",
    SymmesCorner: "- Symmes Corner",
    Wedgemere: "- Wedgemere",
    WinchesterHighlands: "- Winchester Highlands",
    Windsor: "Windsor",
    Winthrop: "Winthrop",
    BelleIsle: "- Belle Isle",
    CottageHill: "- Cottage Hill",
    CottagePark: "- Cottage Park",
    PointShirley: "- Point Shirley",
    WinthropBeach: "- Winthrop Beach",
    WinthropHighlands: "- Winthrop Highlands",
    Woburn: "Woburn",
    Wrentham: "Wrentham",
    Pet:"宠物",
    CatOk:"允许养猫",
    DogOk:"允许养狗",
    CatDogOk:"允许养猫和狗",
    withphotosonly:"只看有照片的",
    mo:"月"
  },
  blog:{
    title:"十几年的经验，为您的资产保驾护航！",
    buy:{
      title:"买房",
      content:`
        <div class="sticky__content">
      <p>Blue Ocean Realty（ 波士顿房产网）由曹悦吉(Rose Hall)女士于2011 年创办，自成立以来迅速成为麻州领先的地产经纪，投资和咨询公司，连年成交总额超过两亿美金，连续数年成为全麻州双语团队遥遥领先的第一名。</p>
        <p>公司在波士顿市区高级公寓一楼拥有200多平米办公室，是麻州实体最大的房地产公司，公司旗下现有近100多位中美专业地产纪人，每位经纪人都拥有丰富的经验，对麻州所有地区房产都有独到的见解，用专业的知识和真诚的态度服务每一个客户， 提供全程购房操作的跟踪式服务，保障投资者的投资权益,，带来最优性价比的资产配置，帮助买家一站式安家波士顿， 波士顿房产网已为成千上万位客户圆了其海外投资置业的梦想。</p>
        </div>
        `
    },
    sale:{
      title:"卖房",
      content:`
        <div class="sticky__content"><p>波士顿房产网拥有分工明确的专家团队，负责专业摄影，房屋装扮，价格分析，宣传推广等环节，用十几年的售房经验和社会关系为您保驾护航。资深经纪的专业知识和人脉，造就出一支强大的高端地产营销团队，让卖房更加专业高效，帮助卖家，获取最高的价格。并得到了中美客户的一致好评。</p></div>`
    },
    tg:{
      title:"房产托管",
      content:`<p class="sticky__content">波士顿房产网旗下管理公司已托管了近1000套房，管理房产遍布全麻州。拥有近三十多位全职房屋管理专业人员和近三十多位维修人员，24小时接听维修电话，周一到周五9:30AM-5:30 PM无间断实体办公，为客户提供双语服务。</p>`
    },
    rent:{
      title:"房屋租赁",
      content:`<p class="sticky__content">除了提供专业的物业管理还提供管家式租赁管理服务，与百家租房平台合作宣传房源。后期拥有专业的审核以及文书团队确保房东利益，获得超出预期的投资回报！对于海外客户，可以做到足不出户，无忧托管。</p>`
    }
  },
  email:"rose@blueoceanboston.com",
  tel:"8572077579",
  startYear:"开始年份",
  endYear:"结束年份",
  beforeUpdate:"天前更新",
  justUpdate:"just updated",
  otherbtn:"其它热门地区",
  openMorebtn:"展开更多条件",
  avail: "入住日期",
  weChatofficialaccount:'扫码经理真人微信咨询',
  wechatconsulting:'关注微信公众号涨知识',
  followMiniPrograms:'关注小程序',
  AvailableDate:'可租日期',
  IncludeHeat:'含暖气',
  IncludeGas:'含燃气',
  Room:'卧室',
  Fee:'费用',
  Parking:'车位情况',
  IncludeElectricity:'含电费',
  IncludeHotWater:'含热水',
  BedInfo:'卧室信息',
  Pet:'宠物',
  Zip:'邮编',
  hotareas:"热门城市",
  MoreLexingtonAreas:"Lexington更多热门地",
  MoreNewtonAreas:"Newton更多热门地区",
  MoreWellesleyAreas:"Wellesley更多热门地区",
  MoreWestonAreas:"Weston更多热门地区"
}
