<template>
  <!-- 搜索区域-->
  <div class="search__form clearfix">
    <input @focus="searchFocusEvent" @blur="searchBlurEvent" type="text" :placeholder="$t('banner.searchplace')" name="" id="" class="search__input" :class="{'search__form--focus' : searchFocus}">
    <button class="search__btn"><svg t="1614226645744" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1158" width="22" height="22"><path d="M131.41481 765.723624a448.753665 448.753665 0 0 0 492.189377 96.099976 442.97044 442.97044 0 0 0 110.74261-67.429945L951.279266 1011.449171a41.959144 41.959144 0 0 0 30.146599 12.304734 42.451334 42.451334 0 0 0 42.574381-42.574381 42.328286 42.328286 0 0 0-12.304734-30.146599L794.393901 734.223504A448.876712 448.876712 0 0 0 861.331657 273.903389 448.876712 448.876712 0 0 0 273.903635 35.314588 448.261476 448.261476 0 0 0 0.000246 448.630618a447.277097 447.277097 0 0 0 131.414564 317.093006zM85.272056 448.630618A363.358808 363.358808 0 1 1 448.630864 812.112473 363.604903 363.604903 0 0 1 85.272056 448.630618z" fill="#1296db" p-id="1159"></path></svg></button>
  </div>
</template>

<script>
export default {
  name: 'search',
  data(){
    return {
      searchFocus:false
    }
  },
  methods:{
    searchFocusEvent(){
      this.searchFocus = true;
    },
    searchBlurEvent(){
      this.searchFocus = false;
    }
  }
};
</script>

<style scoped lang="scss">
  /*搜索区域*/
  .search__form{background:#fff;width: 600px;margin-top: 20px;border-radius: 5px;border: 1px solid transparent;}
  .search__form--focus{border-color:#006aff;box-shadow: 0 0 10px #fff;}
  .search__input{float: left;height: 68px;border: none;padding: 0 10px 0 20px;border-radius: 5px;width: 520px;outline: none;font-size: 16px;}
  .search__btn{margin-top: 20px;background: none;border: none;outline: none;cursor: pointer;}
</style>
