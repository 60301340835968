import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Layout from '@/views/Layout';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta:{
      fixedNav:true
    }
  },
  {
    path: '/page',
    component: () => import(/* webpackChunkName: "home" */ '../views/Layout.vue'),
    children:[
      {
        path: 'sell',
        name: 'sell',
        component: () => import(/* webpackChunkName: "sell" */ '../views/Sell.vue'),
      },
      {
        path: 'trusteeship',
        name: 'trusteeship',
        component: () => import(/* webpackChunkName: "sell" */ '../views/Trusteeship.vue'),
      },
      {
        path: 'about',
        name: 'about',
        component: () => import(/* webpackChunkName: "sell" */ '../views/About.vue'),
      },
      {
        path: 'calculator',
        name: 'calculator',
        component: () => import(/* webpackChunkName: "sell" */ '../views/Calculator.vue'),
      }
    ]
  },
  {
    path: '/buy/list',
    name: 'buy',
    component: () => import(/* webpackChunkName: "buy" */ '../views/List.vue'),
    meta:{
     // footer:true
    }
  },
  {
    path: '/rent/list',
    name: 'rentlist',
    component: () => import(/* webpackChunkName: "rent" */ '../views/Rent.vue'),
    meta:{
      //footer:true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
