<template>
  <div class="fix__box">
    <ul class="fix__list">
      <li class="fix__item onlinecontent__item">
        <span>Message</span>
        </li>
      <li class="fix__item onlinetel__item">
        <span>Tel</span>
        </li>
      <li class="fix__item onlineemail__item">
        <span>Email</span>
        </li>
      </ul>
  </div>
</template>

<script>
export default {
  name: 'suspension'
};
</script>

<style scoped>

</style>
