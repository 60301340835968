<template>
  <div class="layout__body">
    <router-view></router-view>

  </div>
</template>

<script>
import search from '@/components/search';

export default {
  name: 'Layout',
  components:{
    search
  },
  data(){
    return {

    }
  },
  methods:{

  },
  watch:{
    "$route"(n,o){
      this.infoStatus = true;
    }
  }
};
</script>

<style scoped lang="scss">
  .layout__body /deep/{
    background: #f0f0f0;

  }
</style>
