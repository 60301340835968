export default { 
  closeBtn:'Close',
  chinaName:'zh',
  backTop:'Back Top',
  gzxcx:'Focus on applets',
  gzgzh:'Official account',
  wxzy:'Wechat consulting',
  emailNum:'Email',
  telNum:'Tel', 
  wconsolt:'Welcome to consult',
  fangchanweb:'BO SHI DUN Real estate network',
  copyrightaddress:'Hu ICP Bei No. 20004772-2',
  tigongfangyuan:'Provide you with the following listings',
  placeholderSelect:'please select',
  areaCovered:'floor space',
  guanjianci:'key word',
  placeholderAll:'ALL',
  zhiM:'to',
  seeMore:'See More',  
  aboutUs:'About Us',
  ourService:'Our Services',
  cankaoyuegong:'Reference monthly supply (yuan)',
  stowcondition:'Stow condition',
  otherLabel:'Ohter',
  cityStreet:'City/Street',
  priceLabel:'Price',
  houseYear:'House Age',
  login:{
    label:"Login",
    mobile:"Your mobile *",
    captcha:"Enter Security Code",
    typeMobileTip:"Please type your mobile",
    typeCodeTip:"Please type Security Code",
    send:"Send",
    loginOut:"退出"
  },
  company:"Blue Ocean Realty, LLC",
  nav:{
    HOME:"HOME",
    BUY:"BUY",
    RENT:"RENT",
    SELL:"SELL",
    TRUSTEESHIP:"Property Management",
    ABOUT:"ABOUT",
    CALCULATOR:"CALCULATOR"
  },
  banner:{
    slogan:"Find your new home",
    searchplace:"Enter an address, neighborhood, city, or ZIP code"
  },
  homePage:{
    buyahome:{
      title:"Buy a home",
      info:"Find your place with an immersive photo experience and the most listings, including things you won’t find anywhere else.",
      more:"More"
    },
    sellahome:{
      title:"Sell a home",
      info:"Whether you get a cash offer through Blueoceanboston Offers® or choose to sell traditionally, we can help you navigate a successful sale.",
      more:"More"
    },
    rentahome:{
      title:"Rent a home",
      info:"We’re creating a seamless online experience – from shopping on the largest rental network, to applying, to paying rent.",
      more:"More"
    },
    blog:{
      title:"From the Blog"
    },
    copyright:{
      content:"The property listing data and information, or the Images, set forth herein were provided to MLS Property Information Network, Inc. from third party sources, including sellers, lessors, landlords and public records, and were compiled by MLS Property Information Network, Inc. The property listing data and information, and the Images, are for the personal, non commercial use of consumers having a good faith interest in purchasing, leasing or renting listed properties of the type displayed to them and may not be used for any purpose other than to identify prospective properties which such consumers may have a good faith interest in purchasing, leasing or renting. MLS Property Information Network, Inc. and its subscribers disclaim any and all representations and warranties as to the accuracy of the property listing data and information, or as to the accuracy of any of the Images, set forth herein. Privacy Policy Notice: All information collected voluntarily from you will be used to personalize your experience, improve our website and improve our customer service. This information will not be shared with any other third persons or entities."
    }
  },
  footer:{
    rule:"Only real estate professionals who are members of the NATIONAL ASSOCIATION OF REALTORS® may call themselves REALTORS®. All REALTORS® must subscribe to NAR’s strict Code of Ethics, which is based on honesty, professionalism and the protection of the public.",
    address:"15 North Beacon Street, Suite NR1A, Boston, MA 02134",
    email:"Email Me"
  },
  filter:{
    type:{
      Residential:"For Sale",
      Multifamily:"Multifamily",
      Land:"Land",
      Commercial:"Commercial",
    },
    price:{
      label:"Price"
    },
    propertyType:{
      label: "Property type",
      Residential:{
        Condominium:"Condominium",
        ForestServiceCabin:"Forest Service Cabin",
        MobileHome:"Mobile Home",
        PartOwnership:"Part Ownership C/T",
        SingleFamilyResidence:"Single Family Residence",
        Townhouse:"Townhouse"
      },
      Multifamily:{
        Build:"Apt Build 5-12 Units",
        Duplex:"Duplex",
        Quadruplex:"Quadruplex",
        Triplex:"Triplex"
      },
      Land:{
        Land:"Vacant Land",
        Allocation:"Vacant w/Allocation"
      },
      Commercial:{
        Industrial:"Industrial",
        Motel:"Motel/Hotel",
        Office:"Office",
        Professional:"Office,Professional Service",
        RetailSpace:"Office,Retail Space,Other/See Remarks",
        OtherSee:"Other/See Remarks",
        ProfessionalService:"Professional Service",
        ServiceRemarks:"Professional Service,Other/See Remarks",
        GroceryStore:"Retail Space,Grocery Store,Other/See Remarks",
      }
    },
    bedrooms:{
      label:"Bedrooms"
    },
    bathrooms:{
      label:"Bathrooms"
    },
    square:{
      label:"Square Feet"
    },
    keyword:{
      label:"Keywords"
    },
    year:{
      label:"Year"
    },
    lotSize:{
      label:"Lot Size"
    },
    status:{
      label:"Status",
      active:"Active",
      activepending:"Active Pending"
    }
  },
  PropertyFactsFeatures:"Property Facts & Features",
  SchoolsNearby:"Schools Nearby",
  school:{
    tabs:{
      Assigned:"Assigned Schools",
      Elementary:"Elementary",
      Middle:"Middle",
      High:"High"
    }
  },
  listSort:{
    Newest:"Newest",
    Cheapest:"Cheapest",
    OpenHouse:"Open House",
    Price:"Price (High - Low)",
    Largest:"Largest SqFt",
    YearBuilt:"Year Built",
  },
  favourite:{
    toBuyFavourite:"To Buy Favourite",
    toRentFavourite:"To Rent Favourite",
  },
  detail:{
    beds:"beds",
    baths:"baths",
    sqft:"sqft",
    HomeDetails:"Home Details",
    RATING:"RATING",
    SCHOOLNAME:"SCHOOL NAME",
    ASSIGNEDGRADES:"ASSIGNED GRADES",
    DISTANCE:"DISTANCE",
    STUDENTS:"STUDENTS",
    STUDENTTEACH:"STUDENT/TEACH",
    CHARTER:"CHARTER",
    TITLE1:"TITLE1",
    EXPENDITURE:"EXPENDITURE",
    Parking:"Parking",
    NODATA:"You have not any listings."
  },
  sell:{
    when:{
      title:"When Is the Right Time to Sell?",
      content:`
        <p>Times are changing and you’re thinking about moving the family into a new home. But before you start searching for your next dream home, you’ll need to figure out when you’re going to sell your existing home. The decision is a complex one, especially considering the unpredictable economic environment you’re living in. And while there’s no magic formula for determining the best time to sell a home, there are several factors you should consider.</p>
            <h5>The wrong time to sell</h5>
            <p>This likely won’t come as a big surprise: you should avoid selling your home during the winter holiday season. Tensions run high during the holidays and budgets run low. The result? No one’s really itching to buy, and offers that do come in are often low. So as a general rule of thumb, avoid the months of December and January when selling a home.</p>
            <h5>The best season for selling</h5>
            <p>Conventional wisdom dictates that spring is the best time to sell a home. In spring, the holidays have past, the new school year is still a while away, and gardens and other outdoor spaces tend to look their best. And while spring is the season that sees the largest amount of buyers, it’s also the season when inventories are highest. This means lots of competition, so if you’re going to sell in spring, you really need to get your property to stand out with effective marketing and staging.
            </p>
            <h5>The best day for selling</h5>
            <p>According to research, Thursday is the best day of the week to list your home for sale. By listing your home for sale on a Thursday, you can make it available immediately for weekend showings. Come Saturday – the busiest day for real estate – your home will have only been on sale for two days, which is great for attracting full-price offers. In general, the shorter amount of time your home remains on the market, the higher the offer you’ll receive.</p>
      `
    },
    questions:{
      title:"4 Questions to Ask Before Selling a Home",
      content:`
      <p>Selling your home is a big financial and emotional task, which requires lots of planning. So before you decide to put your home on the market, here are four questions to consider.</p>
            <h5>What is my home worth?</h5>
            <p>Several factors go into determining your home’s market value. These include the condition of the property, square footage, location, amenities, as well as recent comparable property sales. For a snapshot of your home’s current value, you can enter your address into a free online value estimator tool. Alternatively, you can look at a recent property tax bill to get your home’s tax assessed value, which is a percentage of your home’s fair market value. If you know your state’s assessment rate (which is usually between 80 and 90 percent), you can estimate fair market value by dividing your tax assessed value by that assessment rate.
            </p>
            <h5>How can I sell at the highest price possible?</h5>
            <p>There are several simple, low-cost things you can do to boost the value of your home. These include eliminating clutter, washing windows and walls, and installing higher wattage bulbs to create a brighter environment. Larger projects, like adding bedrooms and renovating kitchens and bathrooms, typically yield more substantial increases in sales price. Staging your home, which helps paint a picture for how buyers will use their new space, can also add value.
            </p>
            <h5>How long will my home be on the market?</h5>
            <p>The time your home spends on the market depends on several factors, including location, how competitively your home is priced, and how aggressively your home is marketed. Statistics show that if a home is properly priced, an offer should come in within the first 12 showings. If you want to speed up the sales process, don’t underestimate the value of staging. Research shows that well-staged homes can sell up to 32 percent faster than homes that aren’t staged.</p>
            <h5>How can an agent help?</h5>
            <p>A real estate agent can help with all aspects of the selling process, from pricing to marketing to handling the intricacies of closing. An agent will negotiate on your behalf to secure the best price – and quickest sale – possible. Looking for an agent who can help guide you through the selling process? Feel free to contact us!</p>`
    }
  },
  about:{
    about:{
      title:"About",
      content:`
        <p>We understand that buying or selling a home is more than just a transaction: it’s a life-changing experience. That’s why our team of highly-seasoned real estate professionals is dedicated to providing exceptional, personalized service for all of our clients. We take great pride in the relationships we build and always work relentlessly on the client’s behalf to help them achieve their real estate goals.</p>
            <p>Our team of experts represents the best and brightest in the industry, and we’re always striving to lead the field in research, innovation, and consumer education. Today’s buyers and sellers need a trusted resource that can guide them through the complex world of real estate. With our extensive knowledge and commitment to providing only the best and most timely information to our clients, we are your go-to source for real estate industry insight and advice.</p>
            <h5>Our Mission Statement:</h5>
            <p>Our philosophy is simple: clients come first. We pledge to be in constant communication with our clients, keeping them fully informed throughout the entire buying or selling process. We believe that if you’re not left with an amazing experience, we haven’t done our job. We don’t measure success through achievements or awards, but through the satisfaction of our clients.</p>
            <h5>Choosing a home</h5>
            <p>For most buyers, choosing a home is an emotional process. An agent can assist you in this process by offering objective information about each property you look at. From local community information like schools and zoning to home-specific details like condition and amenities, an agent can help you find exactly what you’re looking for.</p>
            <h5>Making an offer</h5>
            <p>Once you’ve found the home of your dreams, an agent will research recent comparable sales of similar homes in the area to help determine a fair selling price. Based on those comparable sales, as well as other factors like inspections and repairs, an agent will then help you structure an offer and negotiate to get the very best deal possible.</p>
            <h5>Closing</h5>
            <p>Closing, or settlement, can be a complicated process. In some areas, the escrow or title company handles the closing process, while in other areas an attorney handles it. Regardless of where you’re buying, a real estate agent can help ensure that everything goes smoothly.</p>
      `
    },
    analyst:{
      title:"Analyst",
      content:`
            <h5>Blue Ocean Realty LLC seeks Analyst.</h5>
            <h5>Master’s degree in Business Administration required. </h5>
            <br><br>
            <p>Job duties:</p>
            <p>1. Analyze and evaluate current real estate investments: including examining financial statements using technical methodologies such as liquidity analysis, etc.</p>
            <p>2. Develop forecasting models for the company to utilize including financial modeling of property rent rolls</p>
            <p>3. Perform financial analysis on the company’s prospective investments including the use of real estate finance methodologies such as debt service coverage ratio, loan to value ratio, and debt yield</p>
            <p>4. Prepare reports for presenting the results of financial analysis to company management</p>
            <p>5. Other related tasks as assigned</p>
            <p>Work Site: Allston (Boston), MA</p>
            <br>
            <br>
            <p>Mail resume to:</p>
            <p>Blue Ocean Realty LLC</p>
            <p>Attn: Rose Hall</p>
            <p>15 N. Beacon Street, Suite NR1A</p>
            <p>Allston (Boston), MA 02134</p>
      `
    },
    contact:{
      title:"CONTACT",
      title2:"CONTACT",
      Name:"Name",
      Email:"Email Address",
      Phone:"Phone Number (used as password)",
      Wechat:"Wechat No.",
      questions:"Any questions for us?",
      Send:"Send",
      SendSuccess:"Send Success",
      address:"Address",
      formTitle:'Housing Trust',
      sellTitle:'Sell a house'
    }
  },
  min:"Min",
  max:"Max",
  any:"Any",
  keword:"Street, MLS# or Keyword",
  Acre:"Acres",
  Status:"Status",
  More:"More",
  calculator:{
    debx:"等额本息",
    debj:"等额本金",
    ckyg:"参考月供",
    yuan:"元",
    mydj:"每月递减",
    hkze:"还款总额",
    zflx:"支付利息",
    dklx:"贷款类型",
    sydk:"商业贷款",
    dkje:"贷款金额",
    inputmoney:"请输入金额",
    wan:"万",
    dknx:"贷款年限",
    sdlv:"商贷利率",
    qsrsdlv:"请输入商贷利率",
  },
  rent:{
    mo:"mo",
    Enterastreename:"Enter a stree name",
    CityHeighborhood:"City/Heighborhood",
    Any: 'Any',
    Acton: 'Acton',
    Andover: 'Andover',
    Ballardvale: '- Ballardvale',
    InTown: '- In Town',
    Shawsheen: '- Shawsheen',
    ShawsheenHeights: '- Shawsheen Heights',
    SouthAndover: '- South Andover',
    WestAndover: '- West Andover',
    Arlington: 'Arlington',
    ArlingtonCenter: '- Arlington Center',
    ArlingtonHeights: '- Arlington Heights',
    BrattleStation: '- Brattle Station',
    EastArlington: '- East Arlington',
    Morningside: '- Morningside',
    Ashland: 'Ashland',
    Attleboro: 'Attleboro',
    SouthAttleboro: '- South Attleboro',
    Auburn: 'Auburn',
    Auburndale: '- Auburndale',
    Bedford: 'Bedford',
    Belmont: 'Belmont',
    PaysonPark: '- Payson Park',
    Waverley: '- Waverley',
    Berkley: 'Berkley',
    Berkshire: 'Berkshire',
    Berlin: 'Berlin',
    Beverly: 'Beverly',
    BeverlyCove: '- Beverly Cove',
    BeverlyFarms: '- Beverly Farms',
    Briscoe: '- Briscoe',
    'Centerville(Bvly)': '- Centerville (Bvly)',
    Montserrat: '- Montserrat',
    NorthBeverly: '- North Beverly',
    PridesCrossing: '- Prides Crossing',
    RyalSide: '- Ryal Side',
    Billerica: 'Billerica',
    EastBillerica: '- East Billerica',
    NorthBillerica: '- North Billerica',
    NuttingLake: '- Nutting Lake',
    Pattenville: '- Pattenville',
    Pinehurst: '- Pinehurst',
    SouthBillerica: '- South Billerica',
    Boston: 'Boston',
    Allston: '- Allston',
    BackBay: '- Back Bay',
    BayVillage: '- Bay Village',
    BeaconHill: '- Beacon Hill',
    Brighton: '- Brighton',
    Charlestown: '- Charlestown',
    Chinatown: '- Chinatown',
    Dorchester: '- Dorchester',
    EastBoston: '- East Boston',
    Fenway: '- Fenway',
    FinancialDistrict: '- Financial District',
    FortHill: '- Fort Hill',
    HydePark: '- Hyde Park',
    JamaicaPlain: '- Jamaica Plain',
    Kenmore: '- Kenmore',
    LeatherDistrict: '- Leather District',
    Mattapan: '- Mattapan',
    Midtown: '- Midtown',
    MissionHill: '- Mission Hill',
    NorthEnd: '- North End',
    Roslindale: '- Roslindale',
    Roxbury: '- Roxbury',
    SeaportDistrict: '- Seaport District',
    SouthBoston: '- South Boston',
    SouthEnd: '- South End',
    TheatreDistrict: '- Theatre District',
    Waterfront: '- Waterfront',
    WestEnd: '- West End',
    WestRoxbury: '- West Roxbury',
    Boxborough: 'Boxborough',
    Boylston: 'Boylston',
    Braintree: 'Braintree',
    BraintreeHighlands: '- Braintree Highlands',
    EastBraintree: '- East Braintree',
    FiveCorners: '- Five Corners',
    SouthBraintree: '- South Braintree',
    Brookline: 'Brookline',
    Beaconsfield: '- Beaconsfield',
    BrooklineHills: '- Brookline Hills',
    BrooklineVillage: '- Brookline Village',
    ChestnutHill: '- Chestnut Hill',
    CoolidgeCorner: '- Coolidge Corner',
    Longwood: '- Longwood',
    Reservoir: '- Reservoir',
    WashingtonSquare: '- Washington Square',
    Burlington: 'Burlington',
    Cambridge: 'Cambridge',
    Agassiz: '- Agassiz',
    CambridgeHighlands: '- Cambridge Highlands',
    Cambridgeport: '- Cambridgeport',
    CentralSquare: '- Central Square',
    EastCambridge: '- East Cambridge',
    HarvardSquare: '- Harvard Square',
    HuronVillage: '- Huron Village',
    InmanSquare: '- Inman Square',
    KendallSquare: '- Kendall Square',
    MidCambridge: '- Mid Cambridge',
    NeighborhoodNine: '- Neighborhood Nine',
    NorthCambridge: '- North Cambridge',
    PorterSquare: '- Porter Square',
    Riverside: '- Riverside',
    WellingtonHarrington: '- Wellington-Harrington',
    WestCambridge: '- West Cambridge',
    Canton: 'Canton',
    CantonJunction: '- Canton Junction',
    Ponkapoag: '- Ponkapoag',
    Springdale: '- Springdale',
    Chelsea: 'Chelsea',
    Concord: 'Concord',
    NineAcreCorner: '- Nine Acre Corner',
    WestConcord: '- West Concord',
    Danvers: 'Danvers',
    BurleysCorner: '- Burleys Corner',
    DanversCenter: '- Danvers Center',
    Danversport: '- Danversport',
    EastDanvers: '- East Danvers',
    Hathorne: '- Hathorne',
    Putnamville: '- Putnamville',
    Tapleyville: '- Tapleyville',
    Dartmouth: 'Dartmouth',
    NorthDartmouth: 'North Dartmouth',
    SouthDartmouth: 'South Dartmouth',
    Dedham: 'Dedham',
    Ashcroft: '- Ashcroft',
    EastDedham: '- East Dedham',
    Endicott: '- Endicott',
    FairbanksPark: '- Fairbanks Park',
    Greenlodge: '- Greenlodge',
    Oakdale: '- Oakdale',
    ReadvilleManor: '- Readville Manor',
    Riverdale: '- Riverdale',
    TheManor: '- The Manor',
    Dover: 'Dover',
    EastBridgewater: 'East Bridgewater',
    Easton: 'Easton',
    Everett: 'Everett',
    Glendale: '- Glendale',
    WashingtonPark: '- Washington Park',
    WestEverett: '- West Everett',
    Fitchburg: '- Fitchburg',
    Foxboro: 'Foxboro',
    Framingham: 'Framingham',
    Coburnville: '- Coburnville',
    FraminghamCenter: '- Framingham Center',
    Lokerville: '- Lokerville',
    Nobscot: '- Nobscot',
    Saxonville: '- Saxonville',
    Franklin: 'Franklin',
    Unionville: '- Unionville',
    Wadsworth: '- Wadsworth',
    Gardner: 'Gardner',
    Gloucester: 'Gloucester',
    Annisquam: '- Annisquam',
    BayView: '- Bay View',
    BrierNeck: '- Brier Neck',
    EastGloucester: '- East Gloucester',
    EasternPoint: '- Eastern Point',
    Lanesville: '- Lanesville',
    Magnolia: '- Magnolia',
    WestGloucester: '- West Gloucester',
    Hamilton: 'Hamilton',
    SouthHamilton: '- South Hamilton',
    Harvard: '- Harvard',
    Huntington: 'Huntington',
    Jefferson: 'Jefferson',
    Kingston: 'Kingston',
    IndianPond: '- Indian Pond',
    LakePleasant: 'Lake Pleasant',
    Lancaster: '- Lancaster',
    Lawrence: 'Lawrence',
    NorthLawrence: '- North Lawrence',
    SouthLawrence: '- South Lawrence',
    Lexington: 'Lexington',
    NorthLexington: '- North Lexington',
    PiercesBridge: '- Pierces Bridge',
    Lowell: 'Lowell',
    Belvidere: '- Belvidere',
    Centralville: '- Centralville',
    Downtown: '- Downtown',
    Highlands: '- Highlands',
    Pawtucketville: '- Pawtucketville',
    SouthLowell: '- South Lowell',
    Lynn: 'Lynn',
    DiamondDistrict: '- Diamond District',
    EastLynn: '- East Lynn',
    LynnCommon: '- Lynn Common',
    LynnWoods: '- Lynn Woods',
    Lynnhurst: '- Lynnhurst',
    WestLynn: '- West Lynn',
    Wyoma: '- Wyoma',
    Lynnfield: 'Lynnfield',
    SouthLynnfield: '- South Lynnfield',
    Malden: 'Malden',
    BellRock: '- Bell Rock',
    BelmontHill: '- Belmont Hill',
    Edgeworth: '- Edgeworth',
    Faulkner: '- Faulkner',
    Fells: '- Fells',
    Forestdale: '- Forestdale',
    Linden: '- Linden',
    Maplewood: '- Maplewood',
    MiddlesexFells: '- Middlesex Fells',
    OakGrove: '- Oak Grove',
    Mansfield: 'Mansfield',
    EastMansfield: '- East Mansfield',
    Robinsonville: '- Robinsonville',
    WestMansfield: '- West Mansfield',
    Whiteville: '- Whiteville',
    Marlborough: 'Marlborough',
    EastMarlborough: '- East Marlborough',
    Medford: 'Medford',
    CollegeHill: '- College Hill',
    MedfordHillside: '- Medford Hillside',
    NorthMedford: '- North Medford',
    TuftsUniversity: '- Tufts University',
    Wellington: '- Wellington',
    WestMedford: 'West Medford',
    Melrose: 'Melrose',
    MelroseHighlands: '- Melrose Highlands',
    MountHood: '- Mount Hood',
    Wyoming: '- Wyoming',
    Milford: 'Milford',
    Milton: 'Milton',
    MiltonVillage: 'Milton Village',
    Natick: 'Natick',
    EastNatick: '- East Natick',
    Felchville: '- Felchville',
    Morseville: '- Morseville',
    NorthNatick: '- North Natick',
    SouthNatick: '- South Natick',
    WestNatick: '- West Natick',
    Needham: 'Needham',
    BirdsHill: '- Birds Hill',
    CharlesRiverVillage: '- Charles River Village',
    NeedhamHeights: 'Needham Heights',
    NeedhamJunction: '- Needham Junction',
    NewBedford: 'New Bedford',
    Center: '- Center',
    FarNorth: '- Far North',
    North: '- North',
    Northwest: '- Northwest',
    South: '- South',
    West: '- West',
    NewBraintree: 'New Braintree',
    NewSalem: 'New Salem',
    Newbury: 'Newbury',
    Byfield: '- Byfield',
    PlumIsland: '- Plum Island',
    Newton: 'Newton',
    Eliot: '- Eliot',
    NewtonCenter: '- Newton Center',
    NewtonCorner: '- Newton Corner',
    NewtonHighlands: '- Newton Highlands',
    NewtonLowerFalls: '- Newton Lower Falls',
    NewtonUpperFalls: '- Newton Upper Falls',
    Newtonville: '- Newtonville',
    Nonantum: '- Nonantum',
    Thompsonville: '- Thompsonville',
    Waban: 'Waban',
    WestNewton: '- West Newton',
    WoodlandStation: '- Woodland Station',
    NorthAttleboro: 'North Attleboro',
    NorthReading: 'North Reading',
    MartinsPond: '- Martins Pond',
    WestVillage: '- West Village',
    Norton: 'Norton',
    Norwood: 'Norwood',
    'Orleans(village)': '- Orleans (village)',
    Peabody: 'Peabody',
    NeedhamCorner: '- Needham Corner',
    SouthPeabody: '- South Peabody',
    WestPeabody: '- West Peabody',
    Plymouth: 'Plymouth',
    CedarBushes: '- Cedar Bushes',
    Cedarville: '- Cedarville',
    Chiltonville: '- Chiltonville',
    Cordage: '- Cordage',
    Ellisville: '- Ellisville',
    LongPond: '- Long Pond',
    Manomet: '- Manomet',
    NamelocHeights: '- Nameloc Heights',
    NorthPlymouth: '- North Plymouth',
    Pinehills: '- Pinehills',
    Pondville: '- Pondville',
    Saquish: '- Saquish',
    SouthPlymouth: '- South Plymouth',
    SouthPond: '- South Pond',
    Vallersville: '- Vallersville',
    WestPlymouth: '- West Plymouth',
    Quincy: 'Quincy',
    AdamsShore: '- Adams Shore',
    Atlantic: '- Atlantic',
    Germantown: '- Germantown',
    HoughsNeck: '- Houghs Neck',
    MarinaBay: '- Marina Bay',
    Merrymount: '- Merrymount',
    Montclair: '- Montclair',
    NorfolkDowns: '- Norfolk Downs',
    NorthQuincy: '- North Quincy',
    QuincyCenter: '- Quincy Center',
    QuincyNeck: '- Quincy Neck',
    QuincyPoint: '- Quincy Point',
    RockIsland: '- Rock Island',
    SouthQuincy: '- South Quincy',
    Squantum: '- Squantum',
    WestQuincy: '- West Quincy',
    Wollaston: '- Wollaston',
    WollastonHeights: '- Wollaston Heights',
    Reading: 'Reading',
    Revere: 'Revere',
    Beachmont: '- Beachmont',
    CrescentBeach: '- Crescent Beach',
    FranklinPark: '- Franklin Park',
    OakIsland: '- Oak Island',
    PointofPines: '- Point of Pines',
    RevereBeach: '- Revere Beach',
    Rochester: 'Rochester',
    Salem: 'Salem',
    BakersIsland: '- Bakers Island',
    ForestRiver: '- Forest River',
    GallowsHill: '- Gallows Hill',
    SalemNeck: '- Salem Neck',
    SalemWillows: '- Salem Willows',
    SouthSalem: '- South Salem',
    WinterIsland: '- Winter Island',
    Saugus: 'Saugus',
    Sharon: 'Sharon',
    Sherborn: 'Sherborn',
    SouthSherborn: '- South Sherborn',
    Whitneys: '- Whitneys',
    Somerville: 'Somerville',
    BallSquare: '- Ball Square',
    DavisSquare: '- Davis Square',
    EastSomerville: '- East Somerville',
    PowderhouseSquare: '- Powderhouse Square',
    ProspectHill: '- Prospect Hill',
    SpringHill: '- Spring Hill',
    TeeleSquare: '- Teele Square',
    UnionSquare: '- Union Square',
    WestSomerville: '- West Somerville',
    WinterHill: '- Winter Hill',
    Springfield: 'Springfield',
    EastForestPark: '- East Forest Park',
    EastSpringfield: '- East Springfield',
    ForestPark: '- Forest Park',
    HillMcKnight: '- Hill McKnight',
    IndianOrchard: '- Indian Orchard',
    LibertyHeights: '- Liberty Heights',
    NEndBrightwood: '- N End Brightwood',
    PinePtBstnRd: '- Pine Pt Bstn Rd',
    SixteenAcres: '- Sixteen Acres',
    SoEndCentral: '- So End Central',
    Stoneham: 'Stoneham',
    Stoughton: 'Stoughton',
    Sudbury: 'Sudbury',
    NorthSudbury: '- North Sudbury',
    SouthSudbury: '- South Sudbury',
    Swampscott: 'Swampscott',
    Wakefield: 'Wakefield',
    Walpole: 'Walpole',
    Waltham: 'Waltham',
    Watertown: 'Watertown',
    Bemis: '- Bemis',
    EastWatertown: '- East Watertown',
    MountAuburn: '- Mount Auburn',
    Wellesley: 'Wellesley',
    BabsonPark: '- Babson Park',
    Overbrook: '- Overbrook',
    WellesleyFarms: '- Wellesley Farms',
    WellesleyFells: '- Wellesley Fells',
    WellesleyHills: 'Wellesley Hills',
    Wenham: 'Wenham',
    WestBridgewater: 'West Bridgewater',
    WestNewbury: 'West Newbury',
    WestSpringfield: 'West Springfield',
    Weston: 'Weston',
    Hastings: '- Hastings',
    SilverHill: '- Silver Hill',
    Westwood: 'Westwood',
    Wilmington: 'Wilmington',
    Winchester: 'Winchester',
    SymmesCorner: '- Symmes Corner',
    Wedgemere: '- Wedgemere',
    WinchesterHighlands: '- Winchester Highlands',
    Windsor: 'Windsor',
    Winthrop: 'Winthrop',
    BelleIsle: '- Belle Isle',
    CottageHill: '- Cottage Hill',
    CottagePark: '- Cottage Park',
    PointShirley: '- Point Shirley',
    WinthropBeach: '- Winthrop Beach',
    WinthropHighlands: '- Winthrop Highlands',
    Woburn: 'Woburn',
    Wrentham: 'Wrentham',
    Pet:"Pet",
    CatOk:"Cat Ok",
    DogOk:"Dog Ok",
    CatDogOk:"Cat & Dog Ok",
    withphotosonly:"with photos only",
  },
  blog:{
    title:"十几年的经验，为您的资产保驾护航！",
    buy:{
      title:"Introduction",
      content:`
        <div class="sticky__content">
          <p>Founded in 2011, Blue Ocean Realty is the premier real estate group, offering a diverse array of investment, residential, and commercial real estate services for its clients.  With annual sales in excess of $200 million per year, Blue Ocean Realty is a success story of a woman-led entrepreneurial company and excellence in client care. As the CEO and President with over a decade of real estate sales and investment experience, Rose Hall leads a team of about 100 professional agents specialized in real estate sales, investment property, rental and property management.
            </p>
          <p>Blue Ocean Realty has been consistently recognized as the number one bilingual brokerage in Massachusetts. We achieve this by leveraging our diverse and experienced team to provide nothing less than a world class real estate investment experience to our valued clients.  Speaking of world class, our large and modern office is in the heart of Boston in the bustling neighborhood of Allston. With our prime location conveniently located near all of Boston’s major universities, our office serves as a hub connecting our global clients with the best bilingual real estate experts in Boston.</p>
          <p>We are much more than just an office, as we have fully leveraged all the technology available to provide the greatest advantage to our clients.  Through our extensive marketing and networking presence throughout Massachusetts, we have also launched the best regional Chinese-English bilingual news and blogs that connect our clients with both market news as well as numerous real estate and community resources available from our industry.</p>
        </div>
      `
    },
    sale:{
      title:"Sales",
      content:`
        <div class="sticky__content">
          <p>We work tirelessly to assure that our clients obtain the best possible deal on their real estate transactions.  We achieve this excellence by providing our clients access to:</p>
          <p>1. In depth comparative market analyses,</p>
          <p>2. Forward looking insights on market growth potential utilizing our extensive professional network,</p>
          <p>3. Access to our complimentary professional services, including industry leading professional photography and professional staging,</p>
          <p>All of which allows us to provide unbeatable value to our clients.</p>
        </div>
      `
    },
    tg:{
      title:"Property Management",
      content:`
        <p class="sticky__content">
        Blue Ocean Realty also provides access to advanced property management services through our Property Management company.  The Blue Ocean Management Team, consisting of a dedicated, full-time team of management experts and about 30 trusted maintenance contractors, provides worry-free service to our clients.  With convenient online billing and service options and decades of working with international investors, it is no wonder that hundreds of our clients trust us with managing their property portfolios throughout Massachusetts.
        </p>
      `
    },
    rent:{
      title:"Rentals",
      content:`
        <p class="sticky__content">
        Blue Ocean Realty leverages every possible connection to ensure the best advantages to our clients when faced with Boston’s thriving yet complex rental market.  Whether it is finding the best convenient and safe rental locations near Boston’s many universities or ensuring 100% occupancy rates across hundreds of investment properties, Blue Ocean Realty’s experienced team is there to assist.  We have access to the largest selection of rentals in Boston, including hundreds of private/off-market listings that no other agents have access to, ensuring we can place our clients.  We also use industry-leading practices, including stringent background and credit checks, to ensure that our client’s investor properties are safely rented.
        </p>
      `
    }
  },
  email:"frontdesk@blueoceanboston.com",
  tel:"(617)206-5700",
  startYear:"start",
  endYear:"end",
  beforeUpdate:" on the site",
  justUpdate:"just update",
  otherbtn:"other city",
  openMorebtn:"open more search",
  avail: "Avail Date",
  weChatofficialaccount:'Wechat Consulting',
  wechatconsulting:'WeChat official account',
  followMiniPrograms:'Follow MiniPrograms',
  AvailableDate:'AvailableDate',
  IncludeHeat:'IncludeHeat',
  IncludeGas:'IncludeGas',
  Room:'Room',
  Fee:'Fee',
  Parking:'Parking',
  IncludeElectricity:'IncludeElectricity',
  IncludeHotWater:'IncludeHotWater',
  BedInfo:'BedInfo',
  Pet:'Pet',
  hotareas:"Hot Areas",
  MoreLexingtonAreas:"More Lexington Areas",
  MoreNewtonAreas:"More Newton Areas",
  MoreWellesleyAreas:"More Wellesley Areas",
  MoreWestonAreas:"More Weston Areas"
}
